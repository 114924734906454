import React from "react"
import styled, { keyframes } from "styled-components"
import color from "../components/colors"

const AnimatedLogo = () => {
  let isDesktop = false
  if (typeof window !== `undefined`) {
    isDesktop = window.innerWidth > 980
  }

  return (
  <LogoWrapper>
    <LogoMove>
      <LogoRotate>
        <Logo width="500" height="500" viewBox="0 0 500 500">
          <LineBR id="line-br" d="M 290.36831,440.11194 440.87363,179.31739 c 10.6633,-18.58466 -2.742,-41.43466 -24.0687,-41.43466 H 290.36831" />
          <LineBL id="line-bl" d="M 209.63167,137.57804 H 83.19504 c -21.32666,0 -34.73199,23.15469 -24.06866,41.43468 l 150.50529,260.79455" />
          <LineTL id="line-tl" d="M 209.63167,59.88806 59.12638,320.68267 c -10.66333,18.58463 2.742,41.43463 24.06866,41.43463 h 126.43663" />
          <RectL id="rect-l" height="33.208656" width="33.208656" x="193.17964" y="231.41531" />
          <RectR height="33.208656" width="33.208656" x="273.61163" y="231.41531" />
          <LineTR id="line-tr" d="m 290.36831,362.1173 h 126.43663 c 21.3267,0 34.732,-23.15463 24.0687,-41.43463 L 290.36831,59.88806" />
        </Logo>
      </LogoRotate>
    </LogoMove>
    <Logotype>Prime73</Logotype>
  </LogoWrapper>
  )
}

export default AnimatedLogo

const animationDuration = '6s';
const animationIterationCount = '1';
const rectRAppear = keyframes`
  62% {
    opacity: 0;
    transform: scale(0.5);
  }
  67% {
    opacity: 1;
    transform: scale(1.1);
  }
  72% {
    opacity: 1;
    transform: scale(1);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
`
const rectLAppear = keyframes`
  65% {
    opacity: 0;
    transform: scale(0.5);
  }
  70% {
    opacity: 1;
    transform: scale(1.1);
  }
  75% {
    opacity: 1;
    transform: scale(1);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
`
const appear = keyframes`
  65% {
    opacity: 0;
    transform: translateX(0);
  }
  80% {
    opacity: 1;
    transform: translateX(8%);
  }
  100% {
    opacity: 1;
    transform: translateX(8%);
  }
`
const moveWrapper = keyframes`
  37% {
    transform: translateX(0);
  }
  62% {
    transform: translateX(0);
  }
  77% {
    transform: translateX(-8%);
  }
  100% {
    transform: translateX(-8%);
  }
`
const rotateWrapper = keyframes`
  37% {
    transform: scale(0.5) rotateZ(180deg);
  }
  62% {
    transform: scale(0.8) rotateZ(0);
  }
  100% {
    transform: scale(0.8) rotateZ(0);
  }
`
const rotateLine1 = keyframes`
  51% {
    transform: rotateZ(180deg);
    opacity: 0;
  }
  59% {
    transform: rotateZ(0);
    opacity: 1;
  }
  100% {
    transform: rotateZ(0);
    opacity: 1;
  }
`
const rotateLine2 = keyframes`
  54% {
    transform: rotateZ(180deg);
    opacity: 0;
  }
  62% {
    transform: rotateZ(0);
    opacity: 1;
  }
  100% {
    transform: rotateZ(0);
    opacity: 1;
  }
`
const drawLine1 = keyframes`
  40% {
    transform: translateX(-25%);
    stroke-dashoffset: 490;
  }
  50% {
    transform: translateX(0);
    stroke-dashoffset: 0;
  }
  100% {
    transform: translateX(0);
    stroke-dashoffset: 0;
  }
`
const drawLine2 = keyframes`
  43% {
    transform: translateX(-25%);
    stroke-dashoffset: 490;
  }
  53% {
    transform: translateX(0);
    stroke-dashoffset: 0;
  }
  100% {
    transform: translateX(0);
    stroke-dashoffset: 0;
  }
`
const Logotype = styled.h1`
  position: absolute;
  opacity: 0;
  top: 0;
  left: 34%;
  height: 300px;
  line-height: 330px;
  letter-spacing: -0.05em;
  color: ${color.primary};
  font-weight: bold;
  font-size: 7.5rem;
  transform: translateX(0);
  animation-duration: ${animationDuration};
  animation-timing-function: ease;
  animation-iteration-count: ${animationIterationCount};
  animation-name: ${appear};
  animation-fill-mode: forwards;
`
const LogoWrapper = styled.div`
  width: 900px;
  height: 300px;
  margin: 0 auto;
  margin-top: 100px;
  border: 1px solid #efefef;
  position: relative;
`
const LogoMove = styled.div`
  height: 100%;
  transform-origin: center;
  transform: translateX(0);
  animation-duration: ${animationDuration};
  animation-timing-function: ease;
  animation-iteration-count: ${animationIterationCount};
  animation-name: ${moveWrapper};
  animation-fill-mode: forwards;
`
const LogoRotate = styled.div`
  height: 100%;
  width: 60%;
  transform-origin: center;
  transform: scale(0.5) rotateZ(180deg);
  animation-duration: ${animationDuration};
  animation-timing-function: ease;
  animation-iteration-count: ${animationIterationCount};
  animation-name: ${rotateWrapper};
  animation-fill-mode: forwards;
`
const Logo = styled.svg`
  width: 100%;
  height: 100%;
`
const LineDef = styled.path`
  fill: transparent;
  stroke-width: 35px;
  animation-duration: ${animationDuration};
  animation-timing-function: ease;
  animation-fill-mode: forwards;
  animation-iteration-count: ${animationIterationCount};
`
const LineTR = styled(LineDef)`
  transform: translateX(-25%);
  stroke-dasharray: 490;
  stroke-dashoffset: 490;
  stroke: ${color.primaryLight};
  animation-name: ${drawLine2};
`
const LineBR = styled(LineDef)`
  transform: translateX(-25%);
  stroke-dasharray: 490;
  stroke-dashoffset: 490;
  stroke: ${color.primary};
  animation-name: ${drawLine1};
`
const LineTL = styled(LineDef)`
  opacity: 0;
  stroke: ${color.primary};
  transform-origin: center;
  transform: rotateZ(180deg);
  animation-name: ${rotateLine1};
`
const LineBL = styled(LineDef)`
  opacity: 0;
  stroke: ${color.primaryLight};
  transform-origin: center;
  transform: rotateZ(180deg) translateX(-25%);
  animation-name: ${rotateLine2};
`
const RectDef = styled.rect`
  opacity: 0;
  fill: transparent;
`
const RectL = styled(RectDef)`
  fill: ${color.primaryLight};
  transform-origin: center;
  transform: scale(0.5) rotateZ(180deg);
  animation-duration: ${animationDuration};
  animation-timing-function: ease;
  animation-iteration-count: ${animationIterationCount};
  animation-name: ${rectLAppear};
  animation-fill-mode: forwards;
`
const RectR = styled(RectDef)`
  fill: ${color.primary};
  transform-origin: center;
  transform: scale(0.5) rotateZ(180deg);
  animation-duration: ${animationDuration};
  animation-timing-function: ease;
  animation-iteration-count: ${animationIterationCount};
  animation-name: ${rectRAppear};
  animation-fill-mode: forwards;
`
